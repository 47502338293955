import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 500 500"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      d: "M367.8 270.1v108.5c0 4.5-.2 9-.3 13.5 0 1-.4 1.5-1.4 1.5-2.4.1-4.9.2-7.3.2H179.7c-6.7 0-13.4-.9-20-2.1-1-.2-1.9-.8-2.9-1.1-.7-.3-1.3-.7-2-.7-4.2-.5-7.4-2.7-10.3-5.5-1.2-1.2-2.4-2.3-3.4-3.5s-1.9-2.6-2.7-4c-3.6-6.4-5-13.5-5.8-20.7-.9-8.7 0-17.4 0-26-.1-43.7 0-87.4 0-131.1 0-22 0-44-.1-66.1 0-1.8-.1-3.6-.1-5.3.1-4.9 2.6-8.6 5.9-11.7 4.8-4.5 10.6-7.1 17.1-8.6 5.2-1.2 10.5-1.1 15.7-1.1h143.1c.9 0 1.3.4 1.3 1.3v10.8c0 .9-.5 1.3-1.4 1.3-1.3 0-2.7.1-4 .1H166.3c-5.5 0-10.8 1.1-15.6 3.7-1.2.7-2.3 1.6-3.3 2.5-1.3 1.1-2 2.6-2 4.3-.1 3.9-.3 7.8-.3 11.6 0 2.2 1.2 3.9 2.9 5.2 4 3.2 8.6 4.9 13.6 5.2 5.8.4 11.7.6 17.5.6h184.7c1 0 1.9-.1 2.9.1.4.1.9.7.9 1 .1 5.2.1 10.4.1 15.5.1 33.5.1 67.1.1 100.6zm-12.4 3.5v-105c0-1.2-.3-2-1.6-2-1.1 0-2.2-.1-3.3-.1H168.6c-2.2 0-4.5-.2-6.7-.4-5.1-.5-9.8-2.6-14.6-3.9-1.8-.5-2-.5-2 1.4.1 10.2.2 20.5.2 30.8v140.4c0 9.5-.3 19 .9 28.4.8 5.8 3 10.5 8.4 13.2 5.3 2.7 11.2 3.4 17 3.9 4.5.4 9.1.2 13.7.2H351c1 0 2.1-.2 3.1-.2 1-.1 1.5-.5 1.5-1.7-.1-1.5-.1-3.1-.1-4.6-.1-33.5-.1-67-.1-100.4z"
    }, null, -1)
  ])))
}
export default { render: render }